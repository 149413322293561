@import "../../../include";

.PartFormInput {
  &__title {
    margin-bottom: 5px;
    font-size: 16px;
    color: #594057;
  }

  &__label {
    position: relative;

    display: block;

    padding: 0 20px;

    background: #fff;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 8%);

    transition: box-shadow 150ms linear;

    &[data-invalid] {
      box-shadow: 0 0 4px 1px red;
    }
  }

  &__input {
    position: relative;
    z-index: 1;

    display: block;

    width: 100%;
    padding: 20px 0;

    font-size: 18px;
    font-weight: 500;
    color: #36162E;

    background: transparent;
    border: 0;

    transition: color .2s linear;

    -webkit-text-fill-color: #36162E; /* autocomplete text color */

    &::placeholder {
      font-size: 18px;
      font-weight: 500;
      color: #594057;
      opacity: .5;
    }

    &:focus {
      outline: 0;
    }
  }

  &__errorTip {
    opacity: 0;
    color: red;
    padding: 4px 20px;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: opacity 150ms linear;

    [data-invalid] & {
      opacity: 1;
    }
  }
}
