@import "../../include";

.formBox {
  @extend %container;
}

.error {
    color: #FF0000;
    font-weight: bold;
}

.errorMessageContainer {
  margin-top: 15px;
  margin-bottom: 15px;
}

.form {
  position: relative;

  max-width: 900px;
  padding: 60px 40px;

  background: #FFF;
  border-radius: 8px;
  box-shadow: 0 62px 116px rgb(0 0 0 / 12%), 0 18.691px 34.970px rgb(0 0 0 / 7.82%), 0 7.764px 14.525px rgb(0 0 0 / 6%), 0 2.808px 5.254px rgb(0 0 0 / 4.18%);

  @media #{$phone} {
    padding: 40px 20px;
  }

  @media screen and (width <= 550px) {
    padding-right: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
  }
}

.formProgessBar {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  text-align: center;

  &__title {
    margin-right: 15px;

    font-size: 17px;
    color: #ACA0AB;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__stepper {
    position: relative;
    flex: 1;
    height: 2px;
    background: #eaeaea;

    @media #{$phone} {
      flex-basis: 100%;
      margin-top: 20px;
    }
  }

  &__step {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    &_passed {
      background: #A0CC4B;
    }

    &_passed1 {
      width: 25%;
    }

    &_passed2 {
      width: 50%;
    }

    &_passed3 {
      width: 75%;
    }

    &_passed4 {
      width: 100%;
    }
  }

  &__stepMark {
    position: absolute;
    top: -15px;
    transform: translateX(-50%);

    &_1 {
      left: 25%;
    }

    &_2 {
      left: 50%;
    }

    &_3 {
      left: 75%;
    }
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 30px;
    margin: 0 auto 10px;

    background: #D0C9D0;
    border: 4px solid #fff;
    border-radius: 100%;

    &_passed {
      background: #a0cc4b;
    }
  }

  &__stepTitle {
    font-size: 17px;
    font-weight: 500;
    color: #D0C9D0;
    white-space: nowrap;

    &_passed {
      color: #a0cc4b;
    }

    @media #{$phone} {
      font-size: 11px;
    }
  }

  @media #{$phone} {
    flex-wrap: wrap;
  }
}

.radioGroup {
  &_windowsCount {
    margin-bottom: 20px;
  }

  &_windowsAge {
    margin-bottom: 20px;
  }

  &_estimation {
    margin-bottom: 30px;
  }

  &__title {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 300;
    color: #594057;
  }

  &__buttonsContainer {
    display: flex;

    @media #{$phone} {
      flex-wrap: wrap;
    }
  }

  &__button {
    margin-right: 5px;
    margin-bottom: 5px;

    input {
      display: none;
      box-sizing: border-box;
      outline: 0;
    }

    span {
      cursor: pointer;

      display: block;

      padding: 20px 26px;

      font-size: 18px;
      font-weight: 500;
      line-height: 1.06;
      color: rgb(89 64 87 / 50%);

      background: #FFF;
      border: 1px solid #ECECEC;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
    }

    input:checked + span {
      color: #fff;
      background: #FF6D3F;
      box-shadow: 0 2px 8px rgb(255 109 63 / 50%);
    }
  }

  &__errorMessage {
    margin-top: 7px;
    font-size: 16px;
    color: red;
  }
}

.nextButton {
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  // padding: 28px 68px;
  padding: 3px;

  min-height: 74px;
  min-width: 220px;

  font-size: 16px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;

  background: #FF6D3F;
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 27px 56px rgb(255 109 63 / 43%), 0 8.140px 16.88px rgb(255 109 63 / 28%), 0 3.38px 7.01px rgb(255 109 63 / 21.5%), 0 1.22px 2.53px rgb(255 109 63 / 15%), inset 0 12px 28px rgb(255 221 102 / 45%);

  transition: .2s;

  @media screen and (width <= 1600px) {
    width: 100%;
  }
}

.stepContainer {
  display: none;

  &_current {
    display: block;
  }
}

.step1 {
  &__title {
    margin-bottom: 20px;
    font-size: 34px;
    font-weight: 500;

    span {
      background: url("./assets/underline.svg") no-repeat center bottom / 100% auto;
    }

    @media #{$phone} {
      font-size: 24px;
    }
  }

  &__cityButtonsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    align-items: center;

    margin-bottom: 20px;

    @media #{$phone} {
      display: grid; /* grid containers */
      grid-template-columns: repeat(2, 1fr); /* four columns */
      grid-template-rows: repeat(3, 1fr); /* four rows */
      align-items: center;
    }
  }

  &__cityButton {
    cursor: pointer;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    margin: 8%;
    padding: 20px;

    font-size: 16px;
    font-weight: bold;
    font-family: Arial, sans-serif;
    color: #FFF;
    text-align: center;
    text-transform: uppercase;

    background: #FF6D3F;
    border: 0 none;
    border-radius: 4px;
    box-shadow: 0 27px 56px rgb(255 109 63 / 43%), 0 8.139px 16.88px rgb(255 109 63 / 28%), 0 3.38px 7.01px rgb(255 109 63 / 21.5%), 0 1.22px 2.54px rgb(255 109 63 / 15%), inset 0 12px 28px rgb(255 221 102 / 45%);

    transition: .2s;

    @media #{$phone} {
      font-size: 14px;
    }

    input {
      display: none;
      box-sizing: border-box;
      outline: 0;
    }
  }

  &__postalCode {
    display: flex;
    margin-right: -15px;
    margin-bottom: 10px;
    margin-left: -15px;

    @media #{$phone} {
      flex-wrap: wrap;
    }
  }

  &__postalCodeInputContainer {
    flex: 0 0 33.33%;
    padding-right: 15px;
    padding-left: 15px;

    @media #{$phone} {
      flex: 0 0 100%;
    }
  }

  &__usePostalCodeButton {
    cursor: pointer;

    display: inline-flex;
    align-items: center;

    height: 64px;
    margin: 20px 40px;
    padding: 20px;

    font-size: 16px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;

    background: #FF6D3F;
    border: 0 none;
    border-radius: 4px;
    box-shadow: 0 27px 56px rgb(255 109 63 / 43%), 0 8.140px 16.88px rgb(255 109 63 / 28%), 0 3.38px 7.01px rgb(255 109 63 / 21.5%), 0 1.22px 2.54px rgb(255 109 63 / 15%), inset 0 12px 28px rgb(255 221 102 / 45%);

    transition: .2s;

    font-family: Arial, sans-serif;
  }

  &__confidentialText {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 264px;
    margin-top: 13px;

    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    line-height: 21px;
    color: #594057;
  }

  &__confidentialTextIcon {
    margin-right: 5px;
  }

  &__imageZeroPressure {
    position: absolute;
    right: -60px;
    bottom: 20px;
    max-width: 200px;

    img {
      width: 100%;
    }

    @media #{$phone} {
      right: 0;
      transform: translateY(100%);
      margin-bottom: 15px;
    }
  }
}

.step3 {
  &__title {
    margin-bottom: 20px;
    font-size: 34px;
    font-weight: 500;

    @media #{$phone} {
      font-size: 24px;
    }
  }

  &__inputContainer {
    max-width: 460px;
    margin-bottom: 20px;
  }

  &__imageAboutCompany {
    max-width: 445px;
    height: 69px;
    margin-bottom: 20px;
  }
}
